
export type TranslationRequest = {
   id: string;
   text: string;
   sourceLanguage: string;
   targetLanguage: string;
};

type TranslationResponse = {
   id: string;
   text: string;
   language: string;
};

export const translateTexts = async (translationRequests: TranslationRequest[], accessToken: string): Promise<TranslationResponse[]> => {
   try {
      const response = await fetch('https://create2stay-functions2.azurewebsites.net/api/translate', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${accessToken}`
         },
         body: JSON.stringify(translationRequests)
      });

      if (!response.ok) {
         throw new Error(`Translation failed: ${response.statusText}`);
      }

      return await response.json();
   } catch (error) {
      console.error('Translation error:', error);
      throw error;
   }
};
