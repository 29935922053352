import React from 'react';
import {InvoiceLine, useInvoiceLineOrderQuery} from "../../../Queries";
import {Box, Card, CardContent, LinearProgress} from "@mui/material";
import {Thumbnails} from "../../../components/Thumbnails";
import dayjs from "dayjs";
import {BodyText, TitleText} from "../../../layout/Typography";
import {LinkTo} from "../../../components/LinkTo";
import {OrderPagePath} from "../../shop/orders/OrderPage";

export const InvoiceLineCommission = ({
                                         type,
                                         invoiceLine,
                                         orderLineId
                                      }: {
   type?: string;
   invoiceLine: Partial<InvoiceLine>;
   orderLineId: string;
}) => {
   const {data, loading} = useInvoiceLineOrderQuery({
      variables: {
         lineId: orderLineId
      }
   });

   if (loading) {
      return <LinearProgress/>;
   }

   const orderLine = data?.OrderLineById;
   const order = data?.OrderById;

   // If we have an order line, show that specific line's details
   if (orderLine?.order) {
      return (
         <Card sx={{display: 'flex', minWidth: '600px'}}>
            <Box sx={{
               width: '120px',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center'
            }}>
               <Thumbnails
                  legendPosition="off"
                  hideBorder
                  imageUrls={orderLine.stockItem?.imageUrls || []}
                  size={100}
                  maxImages={1}
               />
            </Box>
            <CardContent sx={{flex: '1 0 auto'}}>
               <TitleText type="h2">
                  Order:{' '}
                  <LinkTo
                     to={OrderPagePath(
                        invoiceLine.brandHouseId,
                        orderLine.stockItem?.shopId,
                        orderLine.order._id
                     )}
                  >
                     {orderLine.order.orderName}
                  </LinkTo>
               </TitleText>
               <BodyText type="body1">
                  {orderLine.stockItem?.title}
               </BodyText>
               <BodyText
                  type="body1"
                  sx={{fontWeight: 'bold', marginBottom: '5px'}}
               >
                  {type} Cost: {Math.round(invoiceLine?.cost || 0) || 'N/A'}{' '}
                  {invoiceLine.currency}
               </BodyText>
               <BodyText type="subtitle2">
                  Added to invoice {dayjs(invoiceLine.lineDate).format("DD. MMMM YYYY")}{' '}
                  (Ordered {dayjs(orderLine.order.orderDate).format('DD-MM-YYYY')})
               </BodyText>
            </CardContent>
         </Card>
      );
   }

   // If we have an order, show a summary of all items
   if (order) {
      // Collect all image URLs from all items
      const allImageUrls = order.lines
         ?.map(line => (line?.stockItem?.imageUrls || [])[0])
         .flat()
         .filter(Boolean) || [];

      return (
         <Card sx={{display: 'flex', minWidth: '600px'}}>
            <Box sx={{
               width: '120px',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center'
            }}>
               <Thumbnails
                  hideBorder
                  legendPosition={"center"}
                  imageUrls={allImageUrls}
                  size={100}
                  maxImages={4}
               />
            </Box>
            <CardContent sx={{flex: '1 0 auto'}}>
               <TitleText type="h2">
                  Order:{' '}
                  <LinkTo
                     to={OrderPagePath(
                        invoiceLine.brandHouseId,
                        order.lines?.[0]?.stockItem?.shopId,
                        order._id
                     )}
                  >
                     {order.orderName}
                  </LinkTo>
               </TitleText>
               <BodyText type="body1">
                  {order.lines?.length || 0} items in order
               </BodyText>
               <BodyText
                  type="body1"
                  sx={{fontWeight: 'bold', marginBottom: '5px'}}
               >
                  {type} Cost: {Math.round(invoiceLine?.cost || 0) || 'N/A'}{' '}
                  {invoiceLine.currency}
               </BodyText>
               <BodyText type="subtitle2">
                  Added to invoice {dayjs(invoiceLine.lineDate).format("DD. MMMM YYYY")}{' '}
                  (Ordered {dayjs(order.orderDate).format('DD-MM-YYYY')})
               </BodyText>
            </CardContent>
         </Card>
      );
   }

   // Fallback for no data
   return (
      <Card sx={{display: 'flex', minWidth: '600px'}}>
         <CardContent>
            <BodyText type="body1">No order data available</BodyText>
         </CardContent>
      </Card>
   );
};

