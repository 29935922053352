import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ParamBrandHouse, ParamBrandItem, ParamBrandProductVariantSize} from "../../Parameters";
import {
   BrandItem,
   BrandItemImage,
   BrandItemInputInput,
   BrandItemsListManyDocument,
   BrandItemStateEnum,
   EnumBrandItemItemState,
   EnumBrandSettingStockItemConfigMasterImageTypes,
   FragmentBrandItemResolvedFragmentDoc,
   ImagePerspectiveEnum,
   ImageTypeEnum,
   LocalizationEntryInput,
   PricingInput,
   useBrandItemPageDeleteMutation,
   useBrandItemPageProductVariantSizeQuery,
   useBrandItemPageSettingsQuery,
   useBrandItemQuery,
   useBrandItemSettingsQuery,
   useBrandItemStatusPushMutation,
   useBrandItemUpdateMutation,
   useBrandOptionsQuery
} from "../../Queries";
import {LinearProgress} from "@mui/material";
import {
   BrandEditorImage,
   BrandEditorItem,
   BrandEditorSeason,
   BrandItemEditor,
   LocalizedField
} from "./brandItem/BrandItemEditor";
import {BrandItemDialog, BrandItemDialogLine} from "./BrandItemDialog";
import {Dialogs} from "../../DialogProvider";
import {SavingUI} from "../../SavingProvider";
import Page from "../../layout/Page";
import PageContentTabs, {PageTab} from "../../layout/PageContentTabs";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {BrandItemOrders} from "./BrandItemOrders";
import {BrandItemShops} from "./BrandItemShops";
import {BrandItemsPagePath} from "./BrandItemsPage";
import {TitleText} from "../../layout/Typography";
import {DateFormat} from "../../utility/DateFormat";
import {BrandTradeInPagePath} from "./tradeins/BrandTradeInPage";
import {ConsumerPagePath} from "../shop/masterdata/consumers/ConsumerPage";
import InfoIcon from "@mui/icons-material/Info";
import {Thumbnails} from "../../components/Thumbnails";
import {Camera, Fullscreen, FullscreenExit} from "@mui/icons-material";
import useLocalStorage from "../../hooks/useLocalStorage";
import {useApolloClient} from "@apollo/client";
import {PubSub} from "../../components/PubSub";
import {ObjectHistory} from "../components/ObjectHistory";
import {BrandItemTracking} from "./BrandItemTracking";

const filterImages = (settings?: any, masterImages?: any[]) => {
   const images = masterImages?.filter(image => settings?.masterImageTypes?.includes((image.type || EnumBrandSettingStockItemConfigMasterImageTypes.OTHER))) || []
   return images.slice(0, settings?.masterImageCount || 5)
}

export const BrandItemPagePath = (brandHouseId: string, brandItemId: string, drawer: boolean) => {
   if (drawer) {
      return "/brands/" + brandHouseId + "/stock/" + brandItemId
   } else {
      return "/brands/" + brandHouseId + "/stock/edit/" + brandItemId
   }
}

type BrandItemPageProps = {
   inDrawer: boolean
}

export const BrandItemPage = ({inDrawer}: BrandItemPageProps) => {
   const {brandHouseId, brandItemId} = useParams<ParamBrandHouse & ParamBrandItem>()
   return <BrandItemPageCore brandItemId={brandItemId} brandHouseId={brandHouseId} inDrawer={inDrawer}/>
}

type BrandItemPageCoreProps = {
   brandItemId: string
   brandHouseId: string
   inDrawer: boolean
}

export const BrandItemPageCore = ({brandItemId, brandHouseId, inDrawer}: BrandItemPageCoreProps) => {
   const {data, refetch} = useBrandItemQuery({
      variables: {
         brandItemId
      },
      fetchPolicy: "no-cache"
   });
   const {data: settings} = useBrandItemPageSettingsQuery({
      variables: {
         brandHouseId
      }
   })

   const [brandItemUpdate] = useBrandItemUpdateMutation()
   const [brandItem, setBrandItem] = useState<BrandItem | undefined>()

   const onRefetch = async () => {
      const {data: refetchedItem} = await refetch({
         brandItemId
      });
      setBrandItem(refetchedItem?.BrandItem as any as BrandItem)
   }

   useEffect(() => {
      if (data) {
         setBrandItem(data.BrandItem as any as BrandItem)
      }
   }, [data]);

   if (!data || !brandItem || !settings) {
      return <LinearProgress/>
   }

   const onChangeMasterData = async (brandProductSizeId: string) => {
      const {data: updatedData} = await brandItemUpdate({
         variables: {
            brandHouseId: brandHouseId,
            input: {
               _id: brandItemId,
               brandProductSizeId: brandProductSizeId,
               images: data?.BrandItem?.images?.filter(image => !image!.brandProductImageId).map(img => ({
                  _id: img!._id,
               })),
               nameLocalized: null,
               descriptionLocalized: null,
               colorLocalized: null,
               sizeLocalized: null,
               typeLocalized: null,
               vendor: null,
               originalEan: null,
               originalSku: null
            }
         }
      });

      const brandItem = updatedData?.BrandItemUpdate as any as BrandItem;
      const images = filterImages(
         settings.ShopMany?.[0]?.settings?.stockItemConfig,
         updatedData?.BrandItemUpdate?.brandProductSize?.brandVariant?.images || [])
         .map((image) => {
            return {
               brandProductImageId: image?._id
            }
         });
      for (const image of images) {
         if (image && brandItem.images) {
            brandItem.images.push(image as any)
         }
      }
      setBrandItem(brandItem)
   }

   if (!data.BrandItem) {
      return <div>Unknown item</div>
   }

   return <BrandItemPageInner
      brandHouseId={brandHouseId}
      brandItem={brandItem} inDrawer={inDrawer} refetch={onRefetch}
      onChangeMasterData={onChangeMasterData}
   />
}


export const BrandItemCreatePagePath = (brandHouseId: string, brandProductSizeId?: string) => {
   const baseUrl = "/brands/" + brandHouseId + "/stock/create"
   if (brandProductSizeId) {
      return baseUrl + '/' + brandProductSizeId;
   } else {
      return baseUrl
   }
}

export const BrandItemCreateEmptyPage = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>()
   const brandItem = {
      condition: 3,
      itemState: EnumBrandItemItemState.NONE,
      resolved: {}
   } as any as BrandItem;

   const onChangeMasterData = async () => {
      // Nothing?
   }

   return <BrandItemPageInner
      brandHouseId={brandHouseId}
      brandItem={brandItem} onChangeMasterData={onChangeMasterData}/>
}

export const BrandItemCreatePage = () => {
   const {brandProductSizeId, brandHouseId} = useParams<ParamBrandProductVariantSize & ParamBrandHouse>()

   const {data} = useBrandItemPageProductVariantSizeQuery({
      variables: {
         brandProductSizeId
      }
   });

   const {data: settings} = useBrandItemPageSettingsQuery({
      variables: {
         brandHouseId
      }
   })

   const onChangeMasterData = async () => {
      // Nothing?
   }

   if (!data || !settings) {
      return <LinearProgress/>
   }
   const images = filterImages(settings.ShopMany?.[0]?.settings?.stockItemConfig, data?.BrandProductSize?.brandVariant?.images || []).map((image, i) => {
      return {
         brandProductImageId: image?._id,
         imageUrl: image!.imageUrl!
      }
   });

   const variant = data?.BrandProductSize?.brandVariant;
   const size = data?.BrandProductSize;
   const product = variant?.brandProduct

   const brandItem = {
      condition: 3,
      itemState: EnumBrandItemItemState.NONE,
      brandProductSizeId: brandProductSizeId,
      images: images,
      resolved: {
         colorLocalized: variant?.colorLocalized,
         sizeLocalized: size?.sizeLocalized,
         nameLocalized: product?.nameLocalized,
         descriptionLocalized: product?.descriptionLocalized,
         beforePrices: variant?.rrp || [],
         originalEan: size?.ean as string,
         originalSku: size?.sku as string,
         images: images
      }
   } as BrandItem;
   return <BrandItemPageInner
      brandHouseId={brandHouseId}
      brandItem={brandItem} onChangeMasterData={onChangeMasterData}/>
}

const toImageInput = (images: BrandEditorImage[]) => {
   return images.map(img => ({
      _id: img._id,
      brandProductImageId: img.brandProductImageId,
      uploadId: img.uploadId,
      imageId: img.imageId,
      altText: img.altText,
      externId: img.externId,
      process: img.process,
      perspective: img.perspective,
      type: img.type,
      originalImage: img.originalImage
   }))
}


type Connecting = {
   search?: string
   connect: boolean,
   lines: BrandItemDialogLine[]
   images?: BrandEditorImage[]
}

type BrandItemPageInnerProps = {
   brandHouseId: string
   brandItem: BrandItem
   refetch?: () => Promise<void>
   onChangeMasterData: (brandProductSizeId: string) => Promise<void>
   inDrawer?: boolean
}

export const BrandItemPageInner = ({
                                      brandHouseId,
                                      brandItem,
                                      refetch,
                                      onChangeMasterData,
                                      inDrawer
                                   }: BrandItemPageInnerProps) => {
   const [connecting, setConnecting] = useState<Connecting | undefined>(undefined);
   const [publish] = useBrandItemStatusPushMutation()

   const [brandItemUpdate] = useBrandItemUpdateMutation()
   const [itemDelete] = useBrandItemPageDeleteMutation()
   const [, setUseDrawer] = useLocalStorage<boolean>('c2s-stockitem-drawer', true);

   const navigate = useNavigate();
   const buttonRef = React.useRef<HTMLButtonElement | null>(null);

   const client = useApolloClient();

   const {data: settings} = useBrandItemSettingsQuery({
      variables: {
         brandHouseId
      }
   })

   const {data: brandOptions} = useBrandOptionsQuery({
      variables: {
         brandHouseId
      }
   })

   const optionSets = useMemo(() => brandOptions?.itemOptions?.map(option => ({
      _id: option?._id!,
      title: option?.title!,
      valueType: option?.valueType,
      values: option?.values?.map(value => {
         return {
            _id: value?._id!,
            label: value?.label!
         }
      }) || []
   })) || [], [brandOptions]);

   const colorOptions = useMemo(() => {
      const colors: string[] = []
      for (const color of (brandOptions?.itemColors || [])) {
         for (const colorEntry of color.values || []) {
            colors.push(colorEntry!.value)
         }
      }
      return colors
   }, [brandOptions]);

   const vendorOptions = useMemo(() => {
      const vendors: string[] = []
      for (const vendor of (brandOptions?.itemVendors || [])) {
         for (const colorEntry of vendor.values || []) {
            vendors.push(colorEntry!.value)
         }
      }
      return vendors
   }, [brandOptions]);


   if (!settings || !brandOptions) {
      return <LinearProgress/>
   }

   const locales = (settings?.BrandSettings?.locales?.filter(locale => locale?.activeItems) || []).map(loc => loc!.locale)

   const productTags = (settings.BrandHouse?.productTags || [])

   const prefix = settings?.ShopMany?.[0]?.settings?.stockItemConfig?.titlePrefix || 'Secondhand'

   const seasons = (settings?.BrandHouse?.seasons || []).map(season => ({
      id: season!._id,
      name: season!.name
   })) as BrandEditorSeason[]

   // Make sure only unique product types are used
   const productTypes = (settings?.BrandHouse?.productTypes || []).map(type => type!.value)
      .filter((value, index, self) => self.indexOf(value) === index)

   // convert LocalizationEntryInput[] to LocalizedField
   const toLocalizedField = (input?: (LocalizationEntryInput | null)[] | null) => {
      const result: LocalizedField[] = []
      for (const locale of (settings?.BrandSettings?.locales?.filter(locale => locale?.activeItems) || [])) {
         const value = input?.find(entry => entry?.locale === locale?.locale)
         result.push({
            locale: locale?.locale!,
            value: value?.value || ''
         })
      }
      return result
   }

   const fromLocalizedField = (input?: LocalizedField[]): LocalizationEntryInput[] | undefined => {
      return input?.filter(inp => !!inp.value);
   }

   const toCurrencies = (prices: PricingInput[]) => {
      const result: PricingInput[] = [];
      for (const currency of (settings?.BrandSettings?.stockItemConfig?.currencies || [])) {
         const value = prices?.find(cur => cur.currency === currency!.currency);
         result.push({
            currency: currency!.currency,
            value: value?.value as number
         })
      }
      return result;
   }

   const onMasterData = (item: BrandEditorItem, search?: string) => {
      const lines: BrandItemDialogLine[] = [];

      const name = item.name?.[0].value
      if (name) {
         lines.push({
            field: 'Name',
            value: name
         });
      }

      const color = item.color?.[0].value
      if (color) {
         lines.push({
            field: 'Color',
            value: color
         });
      }

      const size = item.size?.[0]?.value
      if (size) {
         lines.push({
            field: 'Size',
            value: size
         });
      }

      setConnecting({
         search: search,
         connect: true,
         lines: lines,
         images: item.images
      });
   }

   const sendUpdateToListeners = (id: string) => {
      const brandItem = client.readFragment({
         id: `BrandItemResolved:${id}`, // Note the typename and ID format
         fragment: FragmentBrandItemResolvedFragmentDoc
      });
      if (brandItem) {
         const {images, ...item} = brandItem
         PubSub.emit('FlexList-DataChange', item);
      }
   }

   const onSave = async (item: BrandEditorItem) => {
      // So the checked date should be set to current date, if the brandItem.checked is not set, otherwise use the same
      // date as before
      const checked = item.checked ? (brandItem.checked || new Date()) : null
      const missing = item.missing ? (brandItem.missing || new Date()) : null
      const unavailable = item.unavailable ? (brandItem.unavailable || new Date()) : null

      const updateItem: BrandItemInputInput = {
         _id: item._id,
         brandProductSizeId: item.brandProductSizeId,
         nameLocalized: fromLocalizedField(item.name),
         descriptionLocalized: fromLocalizedField(item.description),
         colorLocalized: fromLocalizedField(item.color),
         sizeLocalized: fromLocalizedField(item.size),
         typeLocalized: fromLocalizedField(item.type),
         commentLocalized: fromLocalizedField(item.comment),
         commentApproved: item.commentApproved,
         vendor: item.vendor,
         originalEan: item.originalEan,
         originalSku: item.originalSku,
         condition: item.condition,
         stockLocation: item.stockLocation,
         stockLocationBag: item.stockLocationBag,
         directPrices: (item.directPrices||[]).filter(price => !!price.value),
         beforePrices: (item.beforePrices as PricingInput[]).filter(price => !!price.value),
         sourceType: item.sourceType,
         images: toImageInput(item.images),
         itemState: item.itemState,
         commodityCode: item.commodityCode,
         checked: checked,
         missing: missing,
         unavailable: unavailable,
         countryOrigin: item.countryOrigin,
         nfcTag: item.nfcTag,
         note: item.note,
         test: item.test,
         itemTags: item.itemTags.map(tag => ({
            tag: tag.tag,
            auto: tag.auto,
         })),
         problems: item.problems.map(prob => ({
            createdAt: prob.createdAt,
            description: prob.description,
            solved: prob.solved,
         })),
         options: item.options,
         seasonIds: item.seasonIds,
         sortorder: item.sortorder
      }

      await SavingUI.process(async () => {
         const {data: itemUpdate} = await brandItemUpdate({
            variables: {
               brandHouseId: brandHouseId,
               input: updateItem
            },
            refetchQueries: [{
               query: BrandItemsListManyDocument,
            }]
         });

         if (item.itemState !== brandItem.itemState as unknown as BrandItemStateEnum && item.itemState === BrandItemStateEnum.READY) {
            const someNotLive = (brandItem.shops || []).some(shop => !shop!.active)
            const liveShops = (settings.ShopMany || []).filter(shop => shop.live)
            if (liveShops.length > 0 && (someNotLive || brandItem.shops?.length === 0)) {
               await publish({
                  variables: {
                     brandItemId: brandItem._id,
                     active: true
                  }
               })
            }
         }
         sendUpdateToListeners(String(itemUpdate?.BrandItemUpdate?._id));
         refetch && await refetch();
         if (!inDrawer) {
            navigate(BrandItemPagePath(brandHouseId, itemUpdate?.BrandItemUpdate?._id, !!inDrawer), {
               replace: true
            })
         }
      }, 'Stock Item has been saved', 'Please wait')
   }

   const resolved = brandItem.resolved;

   const mapImages = (images: BrandItemImage[]): BrandEditorImage[] => {
      return images?.map((img: BrandItemImage) => ({
         _id: img._id,
         uploadId: img.uploadId!,
         imageId: img.imageId!,
         altText: img.altText!,
         externId: img.externId!,
         type: img.type as any as ImageTypeEnum,
         perspective: img.perspective as any as ImagePerspectiveEnum,
         process: img.process!,
         brandProductImageId: img.brandProductImageId!,
         processStart: img.processStart || undefined,
         originalImage: img.originalImage || false
      }))
   }

   const onChangeMasterDataInternal = async (brandProductSizeId: string) => {
      Dialogs.confirm({
         title: 'All fields will be reset to match masterdata',
         subtitle: 'Any changes you have made to this stockitem will be reverted, continue?'
      }, async () => {
         setConnecting(undefined);
         await onChangeMasterData(brandProductSizeId);
      });
   }

   const onDelete = () => {
      Dialogs.confirm({
         title: 'Really delete this item?',
         subtitle: 'This cannot be undone'
      }, async () => {
         await SavingUI.process(async () => {
            await itemDelete({
               variables: {
                  brandItemId: brandItem._id
               }
            });
            navigate(BrandItemsPagePath(brandHouseId))
         }, 'Deleted')
      })
   }

   const onPopup = () => {
      setUseDrawer(!inDrawer);
      navigate(BrandItemPagePath(brandHouseId, brandItem._id, !inDrawer));
   }

   const tabs: PageTab[] = [{
      label: 'Details'
   }]

   if (brandItem?._id) {
      tabs.push({
         label: 'Orders'
      });
      tabs.push({
         label: 'Shops'
      })
      tabs.push({
         label: 'Views'
      })
   }

   return <Page>
      {connecting?.connect && <BrandItemDialog
         search={connecting.search}
         onCancel={() => setConnecting(undefined)}
         brandHouseId={brandHouseId}
         onCreateStockItem={onChangeMasterDataInternal}
         description={connecting?.lines}
         images={connecting.images}
      />}
      <PageContentTabs
         onBack={!inDrawer}
         renderCommands={(tab: string) => {
            return <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
               <Button startIcon={<Camera/>} onClick={(e) => {
                  window.open('https://aya.create2stay.com/retakephotos?item=' + brandItem.vasId + '&brandItemId=' + brandItem._id + '&brandId=' + brandHouseId, '_blank')
               }}>
                  Retake photos
               </Button>
               <Button startIcon={inDrawer ? <Fullscreen/> : <FullscreenExit/>} onClick={onPopup}>
                  {inDrawer ? "Full screen" : "Popup view"}
               </Button>
               {tab === 'Details' && <>
                  <Button variant={"text"} onClick={onDelete}>
                     Delete
                  </Button>
                  <Button ref={buttonRef} variant={"contained"} type={"submit"} form={"brandItemForm"}>
                     Save
                  </Button>
               </>}
            </Grid>
         }}
         tabs={tabs}
         render={(tab: string) => {
            if (tab === 'Details') {
               return <BrandItemEditor
                  brandHouseId={brandHouseId}
                  colorOptions={colorOptions}
                  vendorOptions={vendorOptions}
                  productTypes={productTypes}
                  locales={locales}
                  prefix={prefix}
                  tags={productTags}
                  optionSets={optionSets}
                  seasons={seasons}
                  sourceRender={() => <SourceInfo item={brandItem}/>}
                  item={{
                     _id: brandItem._id,
                     brandProductSizeId: brandItem.brandProductSizeId,
                     createdAt: brandItem.createdAt!,
                     name: toLocalizedField(brandItem.nameLocalized),
                     description: toLocalizedField(brandItem.descriptionLocalized),
                     color: toLocalizedField(brandItem.colorLocalized),
                     size: toLocalizedField(brandItem.sizeLocalized),
                     type: toLocalizedField(brandItem.typeLocalized),
                     comment: toLocalizedField(brandItem.commentLocalized),
                     commentApproved: brandItem.commentApproved || false,
                     vendor: brandItem.vendor as string,
                     originalEan: brandItem.originalEan as string,
                     originalSku: brandItem.originalSku as string,
                     condition: brandItem.condition,
                     stockLocation: brandItem.stockLocation as string,
                     stockLocationBag: brandItem.stockLocationBag as string,
                     beforePrices: toCurrencies(brandItem.beforePrices as PricingInput[]),
                     directPrices: toCurrencies(brandItem.directPrices as PricingInput[]),
                     sourceType: brandItem.sourceType!,
                     images: mapImages(brandItem.images as BrandItemImage[]),
                     itemNr: brandItem.itemNr,
                     itemState: brandItem.itemState as any as BrandItemStateEnum,
                     commodityCode: brandItem.commodityCode!,
                     itemEan: brandItem.itemEan!,
                     checked: !!brandItem.checked,
                     missing: !!brandItem.missing,
                     unavailable: !!brandItem.unavailable,
                     countryOrigin: brandItem.countryOrigin!,
                     nfcTag: brandItem.nfcTag!,
                     note: brandItem.note!,
                     test: brandItem.test || false,
                     itemTags: (brandItem.itemTags || []).map(tag => ({
                        tag: tag!.tag,
                        auto: tag!.auto,
                     })),
                     problems: (brandItem.problems || []).map(prob => ({
                        createdAt: prob!.createdAt,
                        description: prob!.description,
                        solved: prob!.solved,
                     })),
                     seasonIds: brandItem.seasonIds || [],
                     sortorder: brandItem.sortorder || 0,
                     options: (brandItem.options || []).map(option => ({
                        optionSetId: option!.optionSetId,
                        optionValue: option!.optionValue,
                        optionValueId: option!.optionValueId
                     }))
                  }}
                  masterItem={{
                     brandProductSizeId: brandItem.brandProductSizeId,
                     name: toLocalizedField(resolved?.nameLocalized),
                     description: toLocalizedField(resolved?.descriptionLocalized),
                     color: toLocalizedField(resolved?.colorLocalized),
                     size: toLocalizedField(resolved?.sizeLocalized),
                     type: toLocalizedField(resolved?.typeLocalized),
                     vendor: resolved?.vendor || undefined,
                     originalEan: resolved?.originalEan || undefined,
                     originalSku: resolved?.originalSku || undefined,
                     condition: resolved!.condition!,
                     stockLocation: resolved?.stockLocation || undefined,
                     stockLocationBag: resolved?.stockLocationBag || undefined,
                     beforePrices: resolved?.beforePrices as PricingInput[],
                     sourceType: resolved?.sourceType!,
                     images: mapImages(resolved?.images as BrandItemImage[]),
                  } as any}
                  inDrawer={inDrawer}
                  onSave={onSave}
                  onMasterData={onMasterData}
                  tradeInComments={settings?.BrandSettings?.tradeInComments || false}
               />
            } else if (tab === 'Orders') {
               return <BrandItemOrders brandItemId={brandItem?._id} brandHouseId={brandHouseId}/>
            } else if (tab === 'Shops') {
               return <BrandItemShops brandItemId={brandItem?._id}/>
            } else if (tab === 'Views') {
               return <BrandItemTracking brandItemId={brandItem?._id}/>
            } else {
               return <div>Unknown</div>
            }
         }}
      />
      <ObjectHistory refId={brandItem._id} secondary={brandItem.images?.map(img => ({
         refId: img!._id,
         title: 'Image'
      }))}/>

   </Page>
}

type SourceInfoProps = {
   item: BrandItem
}

const SourceInfo = ({item}: SourceInfoProps) => {
   const {brandHouseId} = useParams<ParamBrandHouse>()
   return <Grid container columnSpacing={2} rowSpacing={1}>
      <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
         <TitleText type={"h2"}>Source</TitleText>
      </Grid>
      {!item?.tradeInItemId && <>
         <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
            Source <div>Manually created {DateFormat.toPresent(item?.createdAt)}</div>
         </Grid>
      </>}
      {item?.tradeInItemId && <>
         <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
            Source
            <Link to={BrandTradeInPagePath(brandHouseId, item?.tradeInItem?.tradeInId)}>TradeIn</Link>
         </Grid>
         <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
            {/* eslint-disable-next-line */}
            Previous owner <Link
            to={ConsumerPagePath(brandHouseId, item?.tradeInItem?.shopId, item?.tradeInItem?.tradeIn?.consumerId)}>
            {item?.tradeInItem?.tradeIn?.consumer?.name}
         </Link>
         </Grid>
         {item?.tradeInItem &&
            <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
               <div title={'Estimated voucher value'}>Voucher <InfoIcon
                  style={{color: 'blue', fontSize: '14px'}}/></div>
               <div>{Math.round(item?.tradeInItem?.value || 0)} {item?.tradeInItem?.shop?.currency}</div>
            </Grid>
         }
         {item?.tradeInItem &&
            <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
               <div title={'Estimated voucher value'}>SKU</div>
               {item?.tradeInItem?.sku || ''}
            </Grid>
         }
         {item?.tradeInItem &&
            <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
               <div title={'Estimated voucher value'}>Consumer image</div>
               <Thumbnails size={50} imageUrls={item?.tradeInItem?.potential?.imageUrls || []}/>
            </Grid>
         }
      </>}
      {(item?.reviewInfos || []).length > 0 && <>
         <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
            <TitleText type={"h2"}>Review info</TitleText>
         </Grid>
         <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
            {item?.reviewInfos?.map(review => {
               return <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                  <div>{review?.type}</div>
                  <div>{review?.value}</div>
               </Grid>
            })}</Grid>
      </>}
   </Grid>
}