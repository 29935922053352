import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {Box, Grid, IconButton} from '@mui/material';
import {styled} from '@mui/system';
import {FormInputPrice} from "../../shop/wholesale/form/FormInputPrice";
import {Sync} from "@mui/icons-material";
import Typography from "@mui/material/Typography";

type PriceCurrencyRowProps = {
   currency: string;
   index: number;
   masterBeforePrice?: number | null;
   sellingPrice?: number;
   onRecalculate: () => void; // Add this prop
};

const TabContainer = styled(Box)(({theme}) => ({
   display: 'flex',
   gap: theme.spacing(0.5),
   padding: theme.spacing(0.3),
   backgroundColor: theme.palette.grey[100],
   width: 'fit-content',
}));

const PriceTab = styled(Box, {
   shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected?: boolean }>(({theme, selected}) => ({
   position: 'relative',
   padding: theme.spacing(0.5, 2),
   borderRadius: 0,
   cursor: 'pointer',
   backgroundColor: selected ? theme.palette.background.paper : 'transparent',
   color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
   boxShadow: selected ? "1px" : 'none',
   transition: 'all 0.2s ease',
   fontSize: '0.8em',
   fontWeight: selected ? 600 : 400,
   '&:hover': {
      backgroundColor: selected ? theme.palette.background.paper : theme.palette.grey[50],
   },
}));

export const PriceCurrencyRow = ({
                                    currency,
                                    index,
                                    masterBeforePrice,
                                    sellingPrice,
                                    onRecalculate
                                 }: PriceCurrencyRowProps) => {
   const {watch} = useFormContext();
   const [priceMode, setPriceMode] = useState<'rrp' | 'direct'>(() => {
      const hasDirectPrice = !!watch(`directPrices.${index}.value`);
      return hasDirectPrice ? 'direct' : 'rrp';
   });

   return (
      <Grid item xs={12} key={currency}>
         <Box sx={{display: 'flex', flexDirection: 'column'}}>

            <TabContainer>
               <PriceTab
                  selected={priceMode === 'rrp'}
                  onClick={() => setPriceMode('rrp')}
               >
                  RRP-based
               </PriceTab>
               <PriceTab
                  selected={priceMode === 'direct'}
                  onClick={() => setPriceMode('direct')}
               >
                  Manual
               </PriceTab>
            </TabContainer>

            <Box sx={{display: priceMode === 'rrp' ? 'block' : 'none'}}>
               <FormInputPrice
                  name={`beforePrices[${index}].value`}
                  label={currency}
                  InputProps={{
                     endAdornment: (
                        <Box sx={{
                           padding: '0px 10px',
                           fontSize: '0.875rem',
                           whiteSpace: 'nowrap',
                           color: 'text.secondary'
                        }}>
                           {sellingPrice && <Box sx={{display: 'inline-block'}}>
                              Selling: {sellingPrice} {currency}
                           </Box>}
                           <IconButton
                              size="small"
                              onClick={onRecalculate}
                           >
                              <Sync fontSize="small"/>
                           </IconButton>
                        </Box>
                     ),
                  }}
                  placeholder={String(masterBeforePrice || '')}
               />
            </Box>

            <Box sx={{display: priceMode === 'direct' ? 'block' : 'none'}}>
               <FormInputPrice
                  name={`directPrices[${index}].value`}
                  label={currency}
                  InputProps={{
                     endAdornment: (
                        <Typography variant="body2" color="text.secondary" sx={{pr: 1, whiteSpace: 'nowrap'}}>
                           Fixed price
                        </Typography>
                     ),
                  }}
               />
            </Box>
         </Box>
      </Grid>
   );
};