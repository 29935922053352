import {useBrandItemStatusProblemsQuery} from "../../Queries";
import {Chip, CircularProgress} from "@mui/material";
import * as React from "react";

type ProblemsProps = {
   brandItemId: string
   shopId?: string
}

export const BrandItemMasterDataStatus = ({brandItemId, shopId}: ProblemsProps) => {
   const {data} = useBrandItemStatusProblemsQuery({
      variables: {
         brandItemId: brandItemId,
         shopId: shopId
      },
      fetchPolicy: "network-only"
   });
   if (!data) {
      return <CircularProgress size={"20px"}/>
   }

   return <div>
      {data?.BrandItemProblems?.map(problem => {
         return <Chip variant={"outlined"} color={"error"} label={problem}/>
      })}
      {(data?.BrandItemProblems || []).length === 0 && <div>OK</div>}
   </div>
}
