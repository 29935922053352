import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import {
   Box,
   Button,
   Drawer,
   Grid,
   LinearProgress,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
   EnumInvoiceLineLineType,
   InvoiceTotalValue,
   useInvoicesListConversionLazyQuery,
   useInvoicesListInvoicesQuery,
   useInvoicesListLinesQuery,
   useInvoicesListLineStockItemQuery,
   useInvoicesListResetMutation
} from "../../../Queries";
import {TitleText} from "../../../layout/Typography";
import {Thumbnails} from "../../../components/Thumbnails";
import {StringHelper} from "../../../utility/StringHelper";
import {useNavigate} from "react-router";
import {StockItemEditPagePath} from "../stockitems/edit/StockItemEditPage";
import {LinkTo} from "../../../components/LinkTo";
import {InvoicesPagePath} from "../../brandhouse/invoices/InvoicesPage";
import {SavingUI} from "../../../SavingProvider";
import {Dialogs} from "../../../DialogProvider";
import {InvoiceLineCommission} from "../../brandhouse/invoices/InvoiceLineCommission";
import {InvoiceLineTradeIn} from "../../brandhouse/invoices/InvoiceLineTradeIn";

export const InvoicesList = () => {
   const [line, setLine] = useState<InvoiceLineSelection | null>();
   const [showAll, setShowAll] = useState(false);

   const {data} = useInvoicesListInvoicesQuery({})
   const [reset] = useInvoicesListResetMutation()
   const classes = useStyles();

   const sections = data?.Invoices?.[0]?.total?.sections || []
   const targetCurrency = "DKK"

   const allInvoiceIds = [...(data?.InvoiceIds || [])].reverse();

   // Show either first 2 or all invoices based on showAll state
   const invoiceIds = showAll ? allInvoiceIds : allInvoiceIds.slice(0, 2);

   if (!data) {
      return <LinearProgress/>
   }

   const resetInvoices = (invoiceId: string) => async () => {
      Dialogs.confirm({
         title: 'Reset invoices',
         subtitle: 'Are you sure you want to reset the invoices?',
      }, async () => {
         await SavingUI.process(async () => {
            await reset({
               variables: {
                  invoiceId
               }
            })
         }, 'Resetting invoices', 'Invoices reset')
      });
   }

   return <div className={classes.page}>
      <div className={classes.invoicePanel}>
         {invoiceIds?.map(invoiceId => {
            const unsortedInvoices = data?.Invoices?.filter(invoice => invoice?.invoiceId === invoiceId) || []
            const invoices = unsortedInvoices.sort((a, b) => {
               return StringHelper.compare(a?.brandHouse?.name, b?.brandHouse?.name)
            });

            const currencyMap = new Map<string, number>();

            const countMap = new Map<string, number>();
            const sumMap = new Map<string, Map<string, number>>();

            return <div key={'invoice_' + invoiceId}>
               <Box sx={{display: 'flex', flexDirection: 'row', justifyItems: 'flex-end'}}>
                  <h1>{invoiceId}</h1>
                  <Box sx={{
                     alignContent: 'center'
                  }}>
                     <Button onClick={resetInvoices(invoiceId)}>Reset</Button>
                  </Box>
               </Box>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableCell/>
                        {sections.map(section => {
                           return <TableCell key={'section_' + section!.title}
                                             colSpan={(section?.lines?.length || 0) * 2}>{section?.title}</TableCell>
                        })}
                        <TableCell/>
                     </TableRow>
                     <TableRow>
                        <TableCell sx={{borderBottom: '2px solid #c0c0c0'}}>Brand</TableCell>
                        {sections.map(section => {
                           return section?.lines?.map(line => {
                              return <TableCell key={'section_column_' + line!.lineType} colSpan={2} sx={{
                                 borderRight: '1px solid #C0C0C0',
                                 borderBottom: '2px solid #c0c0c0'
                              }}>
                                 {line?.title}
                              </TableCell>
                           })
                        })}
                        <TableCell colSpan={2} align={"right"}
                                   sx={{borderBottom: '2px solid #c0c0c0', borderRight: '1px solid #c0c0c0',}}>
                           Total
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {invoices.map(invoice => {
                        const value = currencyMap.get(invoice!.currency!) || 0;
                        currencyMap.set(invoice!.currency!, value + invoice!.total!.total);

                        return <TableRow key={'invoice_' + invoice!._id}>
                           <TableCell>
                              <LinkTo to={InvoicesPagePath(invoice!.brandHouseId!)}>{invoice!.brandHouse!.name}</LinkTo>
                           </TableCell>
                           {invoice?.total?.sections?.map(section => {
                              return section?.lines?.map(line => {
                                 const curCount = countMap.get(line!.lineType!) || 0;
                                 countMap.set(line!.lineType!, curCount + line!.count);

                                 let curSum = sumMap.get(line!.lineType!);
                                 if (!curSum) {
                                    curSum = new Map<string, number>();
                                    sumMap.set(line!.lineType!, curSum);
                                 }
                                 const sumValue = curSum.get(invoice!.currency!) || 0
                                 curSum.set(invoice!.currency!, sumValue + line!.sum);

                                 return <Fragment key={'cell_' + line!.lineType + '_count'}>
                                    <TableCell align={"right"}>
                                       <div onClick={() => setLine({
                                          line: line,
                                          invoiceId: invoice._id
                                       })} style={{cursor: 'pointer'}}>
                                          {line?.count}
                                       </div>
                                    </TableCell>
                                    <TableCell align={"right"} key={'cell_' + line!.lineType + '_sum'}
                                               sx={{borderRight: '1px solid #C0C0C0'}}>
                                       <ConversionCell targetCurrency={targetCurrency}
                                                       sourceCurrency={invoice!.currency!} value={line?.sum}/>
                                    </TableCell>
                                 </Fragment>
                              })
                           })}
                           <TableCell colSpan={2} align={"right"} sx={{borderRight: '1px solid #C0C0C0'}}>
                              <ConversionCell targetCurrency={targetCurrency} sourceCurrency={invoice!.currency!}
                                              value={Math.round(invoice?.total?.total || 0)}/>
                           </TableCell>
                        </TableRow>
                     })}
                     <TableRow>
                        <TableCell sx={{backgroundColor: '#f0f0f0', fontWeight: 'bold'}}>Total</TableCell>
                        {sections.map(section => {
                           return section?.lines?.map(line => {
                              const sum = sumMap.get(line!.lineType!)!;
                              const count = countMap.get(line!.lineType!);
                              return <Fragment key={'cell_' + line!.lineType + '_count'}>
                                 <TableCell key={'cell_' + line!.lineType + '_' + section!.title + '_cell'}
                                            align={"right"} sx={{fontWeight: 'bold', backgroundColor: '#f0f0f0'}}>
                                    {count}
                                 </TableCell>
                                 <TableCell key={'cell_' + line!.lineType + '_' + section!.title + '_cell2'}
                                            align={"right"} sx={{
                                    borderRight: '1px solid #C0C0C0',
                                    fontWeight: 'bold',
                                    backgroundColor: '#f0f0f0'
                                 }}>
                                    <ConversionMap targetCurrency={targetCurrency} values={sum}/>
                                 </TableCell>
                              </Fragment>
                           })
                        })}
                        <TableCell align={"right"} sx={{
                           borderRight: '1px solid #C0C0C0',
                           fontWeight: 'bold',
                           backgroundColor: '#f0f0f0'
                        }}>
                           <ConversionMap targetCurrency={"DKK"} values={currencyMap}/>
                        </TableCell>
                     </TableRow>
                  </TableBody>
               </Table>
            </div>
         })}
         {!showAll && allInvoiceIds.length > 2 && (
            <Box sx={{marginTop: '30px'}}>
               <Button
                  variant="contained"
                  onClick={() => setShowAll(true)}
               >
                  Show all ({allInvoiceIds.length - 2} more)
               </Button>
            </Box>
         )}

      </div>
      <Drawer variant={"temporary"} open={!!line} anchor={"right"} onClose={() => setLine(null)}
              BackdropProps={{invisible: true}}>
         <div className={classes.lineDetails}>
            {line && <InvoiceLineView invoiceId={line.invoiceId} lineType={line.line!.lineType!}/>}
         </div>
      </Drawer>
   </div>;
}

type InvoiceLineSelection = {
   invoiceId: string
   line: InvoiceTotalValue | undefined | null
}


const useStyles = makeStyles({
   page: {
      position: "relative",
      display: 'flex'
   },
   commands: {
      position: 'absolute',
      right: 0
   },
   invoiceTitle: {
      borderBottom: '1px solid #c0c0c0',
      fontWeight: 500
   },
   invoiceLine: {
      borderBottom: '1px solid #c0c0c0'
   },
   lineDetails: {
      marginLeft: '20px',
      padding: '20px'
   },
   invoicePanel: {},
   totalLine: {
      borderBottom: '2px solid #808080'
   }
});

type InvoiceLineViewProps = {
   invoiceId: string
   lineType: EnumInvoiceLineLineType
}

const InvoiceLineView = ({invoiceId, lineType}: InvoiceLineViewProps) => {
   const {data} = useInvoicesListLinesQuery({
      variables: {
         invoiceId: invoiceId,
         lineType: lineType
      }
   });

   return <div>
      {lineType === EnumInvoiceLineLineType.item &&
         <TitleText type={"h2"}>
            Stockitems registered
         </TitleText>
      }
      {data?.InvoiceLines?.map(line => (
         <Box key={line._id} sx={{ marginBottom: '5px' }}>
            {line.lineType === EnumInvoiceLineLineType.item ? (
               <InvoiceLineStockItem stockItemId={line.lineId} />
            ) : line.lineType === EnumInvoiceLineLineType.order_commission ? (
               <InvoiceLineCommission invoiceLine={line} orderLineId={line.lineId} />
            ) : line.lineType === EnumInvoiceLineLineType.order_pickpack ? (
               <InvoiceLineCommission type="Pickpack" invoiceLine={line} orderLineId={line.lineId} />
            ) : line.lineType === EnumInvoiceLineLineType.order_return ? (
               <InvoiceLineCommission type="Return" invoiceLine={line} orderLineId={line.lineId} />
            ) : line.lineType === EnumInvoiceLineLineType.shipping_order ? (
               <InvoiceLineCommission type="Shipping" invoiceLine={line} orderLineId={line.lineId} />
            ) : line.lineType === EnumInvoiceLineLineType.shipping_return ? (
               <InvoiceLineCommission type="Return shipping" invoiceLine={line} orderLineId={line.lineId} />
            ) : line.lineType === EnumInvoiceLineLineType.shipping_tradein ? (
               <InvoiceLineTradeIn invoiceLine={line} tradeInId={line.lineId} />
            ) : (
               <div>{line.cost}</div>
            )}
         </Box>
      ))}
   </div>
}

const InvoiceLineStockItem = ({stockItemId}: { stockItemId: string }) => {
   const {data} = useInvoicesListLineStockItemQuery({
      variables: {stockItemId}
   });
   const navigate = useNavigate();

   if (!data) {
      return <LinearProgress/>
   }

   const onClick = () => {
      if (data?.StockItemById?.shop?.brandHouse?._id) {
         navigate(StockItemEditPagePath(
            data!.StockItemById!.shop!.brandHouse!._id!,
            data!.StockItemById!.shop!._id!,
            data!.StockItemById!._id!
         ));
      }
   }

   return <Grid container style={{minWidth: '500px', cursor: 'pointer'}} onClick={onClick}>
      <Grid item xs={2}>
         {data.StockItemById?.itemNr}
      </Grid>
      <Grid item xs={8}>
         {data.StockItemById?.title}
      </Grid>
      <Grid item xs={2}>
         <Thumbnails imageUrls={data.StockItemById?.imageUrls || []} size={30} maxImages={1}/>
      </Grid>
   </Grid>
}


export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
   list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
   }, {} as Record<K, T[]>);


type ConversionMapProps = {
   targetCurrency: string
   values: Map<string, number>
}
const ConversionMap = ({targetCurrency, values}: ConversionMapProps) => {
   const [calculate] = useInvoicesListConversionLazyQuery();
   const [result, setResult] = useState<number | undefined>();

   useEffect(() => {
      (async function () {
         let total: number = 0;
         const currencies = Array.from(values.keys());
         for (const currency of currencies) {
            const value = values.get(currency)!;
            if (currency === targetCurrency) {
               total += value;
            } else {
               const {data} = await calculate({
                  variables: {
                     inputCurrency: currency,
                     inputValue: value,
                     outputCurrency: targetCurrency
                  }
               });
               total += (data?.ConvertCurrency?.value || 0)
            }
         }
         setResult(total);
      })()
   }, [calculate, targetCurrency, values])

   return <span>{Math.round(result||0)}</span>
}


type ConversionCellProps = {
   targetCurrency: string
   sourceCurrency: string
   value: number | undefined | null
}
const ConversionCell = ({targetCurrency, sourceCurrency, value}: ConversionCellProps) => {
   const [calculate] = useInvoicesListConversionLazyQuery();
   const [result, setResult] = useState<number | undefined>();

   useEffect(() => {
      if (!value) {
         setResult(0);
      } else if (targetCurrency === sourceCurrency) {
         setResult(value!);
      } else {
         (async function () {
            let total: number = 0;

            const {data} = await calculate({
               variables: {
                  inputCurrency: sourceCurrency,
                  inputValue: value!,
                  outputCurrency: targetCurrency
               }
            });
            total += (data?.ConvertCurrency?.value || 0)
            setResult(total);
         })()
      }
   }, [calculate, sourceCurrency, targetCurrency, value])

   return <span>{Math.round(result||0)}</span>
}
