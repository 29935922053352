import {BrandItemShopInfo, useBrandItemStatusItemQuery, useBrandItemStatusPushMutation} from "../../Queries";
import PageContent from "../../layout/PageContent";
import {FrontendIcon} from "../settings/details/FrontendIcon";
import * as React from "react";
import {Box, LinearProgress} from "@mui/material";
import {SavingUI} from "../../SavingProvider";
import {DataColumn, DataTable} from "../../components/DataTable";
import Checkbox from "@mui/material/Checkbox";
import {useRecoilValue} from "recoil";
import {CurrentUserIsShop} from "../../atoms/CurrentUser";
import {BrandItemMasterDataStatus} from "./BrandItemMasterDataStatus";

type BrandItemShopsProps = {
   brandItemId: string
}
export const BrandItemShops = ({brandItemId}: BrandItemShopsProps) => {
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);

   const {data} = useBrandItemStatusItemQuery({
      variables: {
         brandItemId
      },
      fetchPolicy: "no-cache"
   })
   const [publish] = useBrandItemStatusPushMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const onGoShop = (row: BrandItemShopInfo) => () => {
      let externUrl = row.stockItem?.externUrl;
      if (externUrl && externUrl !== '#') {
         if (isBrandHouse) {
            window.location.replace(externUrl);
         } else {
            window.open(externUrl, '_blank');
         }
      } else {

      }
   }

   const onToggleActive = (row: BrandItemShopInfo) => async () => {
      await SavingUI.process(async () => {
         await publish({
            variables: {
               brandItemId: brandItemId,
               shopId: row.shopId,
               active: !row?.active
            },
            refetchQueries: ['BrandItemStatusItem']
         })
      }, 'Updated', 'Updating status for item')
   }

   const columns: DataColumn<BrandItemShopInfo>[] = [{
      field: 'frontendType',
      label: 'Shops',
      render: (typen, row) => {
         return <Box sx={{whiteSpace: 'nowrap', display: 'flex', gap: '10px', cursor: 'pointer'}}
                     onClick={onGoShop(row)}>
            <FrontendIcon size={"20px"} type={row!.shop!.frontendType}/>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
               {row.shop?.name}
               <Box>
                  {row.stockItem?.itemSku}
               </Box>
            </Box>
         </Box>
      }
   }, {
      field: '_id',
      label: 'Selling price',
      render: (_, row) => {
         return <Box sx={{whiteSpace: 'nowrap'}}>
            {!row?.stockItem?._id && <div>
               Not for sale
            </div>}
            {row?.stockItem?._id && <div>
               {row?.stockItem?.sellingPrice} {row?.shop?.currency}
            </div>}
         </Box>
      }
   }, {
      field: '_id',
      label: 'For sale',
      render: (_, row) => {
         return <div>
            <Checkbox onClick={onToggleActive(row)} checked={row?.active} color="primary"/>
         </div>
      }
   }, {
      field: '_id',
      label: 'Master data',
      render: (_, row) => {
         return <div>
            <BrandItemMasterDataStatus brandItemId={row.brandItemId} shopId={row.shopId}/>
         </div>
      },
      width: '55%'
   }]

   return <PageContent>
      <DataTable idField={"_id"} columns={columns} rows={data?.BrandItem?.shops || []}/>
   </PageContent>
}
