import {FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import {Box, Chip, IconButton, Tab, Tabs} from "@mui/material";
import {Languages} from "../../settings/languages/Languages";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import PageContent from "../../../layout/PageContent";
import dayjs from "dayjs";
import {
   BrandItemProblemInput,
   BrandItemStateEnum,
   BrandItemTagInput,
   ImagePerspectiveEnum,
   ImageTypeEnum,
   ItemSourceType,
   OptionInstanceInput,
   PricingInput,
   useRecalculatePricesMutation
} from "../../../Queries";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import SearchIcon from "@mui/icons-material/Search";
import {FormInputHtml} from "../../shop/wholesale/form/FormInputHtml";
import Grid from "@mui/material/Grid";
import {FormInputSelect} from "../../shop/wholesale/form/FormInputSelect";
import {FormInputFree} from "../../shop/wholesale/form/FormInputFree";
import {BodyText, TitleText} from "../../../layout/Typography";
import {EditorOptionSet, OptionSets} from "../../components/OptionSets";
import {BrandItemImages} from "./BrandItemImages";
import {FormInputReadonly} from "../../shop/wholesale/form/FormInputReadonly";
import {FormInputNumber} from "../../shop/wholesale/form/FormInputNumber";
import {FormInputSelectMultiple} from "../../shop/wholesale/form/FormInputSelectMultiple";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {CurrentUserIsShop, CurrentUserState} from "../../../atoms/CurrentUser";
import {useRecoilValue} from "recoil";
import {BrandItemProblems} from "./BrandItemProblems";
import {usePrompt} from "../../../components/useBlocker";
import GetAppIcon from '@mui/icons-material/GetApp';
import {useEditorState} from "react-simple-wysiwyg";
import {FormControlProps} from "@mui/material/FormControl/FormControl";
import {StandardTextFieldProps} from "@mui/material/TextField/TextField";
import {AutoFixHigh} from "@mui/icons-material";
import {translateTexts, TranslationRequest} from "../../../utility/Translations";
import {SavingUI} from "../../../SavingProvider";
import {PriceCurrencyRow} from "./PriceCurrencyRow";

export interface BrandEditorProps {
   brandHouseId: string

   prefix: string
   locales: string[]
   item: BrandEditorItem
   masterItem: BrandEditorItem

   onSave: (item: BrandEditorItem) => Promise<void>
   onMasterData: (item: BrandEditorItem, search?: string) => void

   colorOptions: string[]
   vendorOptions: string[]
   productTypes: string[]
   tags: string[]
   seasons: BrandEditorSeason[]

   optionSets: EditorOptionSet[]

   sourceRender?: () => React.ReactNode

   tradeInComments?: boolean

   inDrawer?: boolean
}

export interface BrandEditorSeason {
   id: string
   name: string
}

export interface BrandEditorItem {
   _id?: string

   brandProductSizeId?: string

   createdAt?: Date

   name: LocalizedField[]
   description: LocalizedField[]
   color: LocalizedField[]
   size: LocalizedField[]
   type: LocalizedField[]
   comment: LocalizedField[]
   commentApproved: boolean

   vendor?: string
   originalEan?: string
   originalSku?: string

   directPrices?: PricingInput[]
   beforePrices?: PricingInput[]
   condition: number

   sourceType?: ItemSourceType
   stockLocation?: string
   stockLocationBag?: string

   itemTags: BrandItemTagInput[]
   itemState: BrandItemStateEnum
   itemNr: number
   itemEan: string

   commodityCode: string
   countryOrigin: string

   checked: boolean
   missing: boolean
   unavailable: boolean
   test: boolean

   nfcTag: string
   note: string

   problems: BrandItemProblemInput[]

   options: OptionInstanceInput[]
   seasonIds: string[]

   sortorder?: number

   images: BrandEditorImage[]
}

export interface BrandEditorImage {
   _id?: string

   process?: boolean

   uploadId?: string
   imageId?: string
   brandProductImageId?: string
   originalImage: boolean

   altText?: string
   externId?: string

   perspective?: ImagePerspectiveEnum
   type?: ImageTypeEnum;
}

export interface LocalizedField {
   locale: string
   value: string
}

export const BrandItemEditor = ({
                                   brandHouseId,
                                   colorOptions,
                                   vendorOptions,
                                   productTypes,
                                   optionSets,
                                   seasons,
                                   tags,
                                   prefix,
                                   locales,
                                   item,
                                   masterItem,
                                   onSave,
                                   onMasterData,
                                   sourceRender,
                                   tradeInComments,
                                   inDrawer
                                }: BrandEditorProps) => {
   const [locale, setLocale] = useState<string>(locales[0])
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);
   const [recaculatePrices] = useRecalculatePricesMutation()
   const [prices, setPrices] = useState<PricingInput[]>([]);
   const [showSizeAlert, setShowSizeAlert] = useState(false);
   const [isTranslating, setIsTranslating] = useState(false);
   const currentUser = useRecoilValue(CurrentUserState);
   const [formKey, setFormKey] = useState(0);

   const form = useForm<BrandEditorItem & { newTag: string }>({
      defaultValues: item
   });

   const {watch, setValue, getValues, handleSubmit, control, formState: {isDirty}} = form;

   const {fields, append, remove} = useFieldArray({
      control,
      name: 'itemTags'
   })

   const {fields: commentFields} = useFieldArray({
      control,
      name: 'comment'
   })

   const {fields: priceFields} = useFieldArray({
      control,
      name: 'beforePrices'
   })

   useEffect(() => {
      form.reset(item)
   }, [item, form])

   const checkSizeConsistency = useCallback((values: BrandEditorItem) => {
      const sizeValues = values.size as LocalizedField[];
      const sizes = sizeValues.map(f => f.value.trim());
      const uniqueSizes = new Set(sizes);

      // If there's more than one unique size (excluding empty strings),
      // or if there are both empty and non-empty values, show the alert
      return uniqueSizes.size > 1 || (uniqueSizes.has('') && uniqueSizes.size > 1);
   }, []);

   useEffect(() => {
      const subscription = watch((value, {name, type}) => {
         if (name?.startsWith('size')) {
            setShowSizeAlert(checkSizeConsistency(value as BrandEditorItem));
         }
      });
      return () => subscription.unsubscribe();
   }, [watch, checkSizeConsistency]);

   const onConnect = () => {
      const text = getValues('name.' + locale as any);
      onMasterData(getValues(), text);
   }

   const breakpoints2Column = {
      xs: 12,
      md: 6
   };

   const breakpoints1Column = {
      xs: 12
   };

   const handleAutoTranslate = async () => {
      await SavingUI.process(async () => {
         if (isTranslating) return;
         setIsTranslating(true);

         try {
            const currentFormValues = getValues();
            const fieldsToTranslate = findFieldsToTranslate(locales, currentFormValues, masterItem);

            // If no fields need translation, exit early
            if (fieldsToTranslate.length === 0) {
               return;
            }

            // Create a single array of all translation requests
            const allTranslationRequests = fieldsToTranslate.map(field => {
               // Prefer English as source, then any other language
               const mainText = field.translations.find(t => t.sourceLanguage === "en") || field.translations[0];
               if (!mainText) return null;

               return {
                  id: `${field.field}.${field.fieldLanguage}`, // Include target language in ID
                  text: mainText.sourceText,
                  sourceLanguage: Languages.get(mainText.sourceLanguage)?.name || mainText.sourceLanguage,
                  targetLanguage: Languages.get(field.fieldLanguage)?.name || field.fieldLanguage,
               };
            }).filter(request => request !== null) as TranslationRequest[];

            // Make a single call to translateTexts with all requests
            const translations = await translateTexts(allTranslationRequests, currentUser?.token || '');

            // Process all translations and update form values
            translations.forEach(translation => {
               // Extract field name and target language from the composite ID
               const [fieldName, targetLang] = translation.id.split('.');

               const fieldArray = (currentFormValues as any)[fieldName] as LocalizedField[];
               const targetIndex = fieldArray.findIndex(f => f.locale === targetLang);

               if (targetIndex !== -1 && translation.text) {
                  setValue(
                     `${fieldName}.${targetIndex}.value` as any,
                     translation.text,
                     {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true
                     }
                  );
               }
            });

            // Update each translated field individually
            translations.forEach(translation => {
               const [fieldName, targetLang] = translation.id.split('.');
               const fieldArray = (currentFormValues as any)[fieldName] as LocalizedField[];
               const targetIndex = fieldArray.findIndex(f => f.locale === targetLang);

               if (targetIndex !== -1 && translation.text) {
                  setValue(
                     `${fieldName}.${targetIndex}.value` as any,
                     translation.text,
                     {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true
                     }
                  );
               }
            });

         } catch (error) {
            console.error('Translation failed:', error);
         } finally {
            setIsTranslating(false);
            setFormKey(prev => prev + 1);
         }
      }, 'Fields have been translated', 'AI is translating empty fields');
   };

   usePrompt('Remember to save changes', isDirty)
   return (
      <FormProvider {...form} key={formKey}>
         <form id={"brandItemForm"} onSubmit={handleSubmit(onSave)} style={{width: '100%'}}>
            <Grid container spacing={1} sx={{marginBottom: '40px'}}>
               <Grid item {...breakpoints2Column}>
                  <PageContent>
                     <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Tabs value={locale} onChange={(e, val) => {
                           setLocale(val);
                        }}>
                           {locales.map(locale => {
                              const language = Languages.get(locale)
                              return <Tab key={'brandItemEditor_tab_' + locale} value={locale} label={language?.name}/>
                           })}

                        </Tabs>
                        <Box>
                           <IconButton
                              title={"AI translation of missing fields"}
                              onClick={handleAutoTranslate}
                              disabled={isTranslating}
                           ><AutoFixHigh/></IconButton>
                        </Box>
                     </Box>

                     <FormInputLocalized
                        field={"name"} locale={locale} label={"Name"}
                        InputProps={{
                           startAdornment: <Box sx={{
                              backgroundColor: '#f0f0f0',
                              padding: '21px 10px 4px 14px',
                              marginLeft: '-12px',
                              marginRight: '7px',
                              fontSize: '90%',
                              whiteSpace: 'nowrap',
                              maxWidth: '350px'
                           }}>
                              {prefix}
                           </Box>,
                           endAdornment: <Box sx={{display: 'flex', flexDirection: 'row'}}>
                              <IconButton title={"Search for master data"}
                                          onClick={onConnect}><SearchIcon/></IconButton>
                           </Box>
                        }} placeholders={masterItem.name}/>

                     <FormInputLocalized
                        field={"description"}
                        label={"Description"}
                        html={true}
                        locale={locale}
                        placeholders={masterItem.description}
                     />

                     <Grid container sx={{marginTop: '1px'}} spacing={1}>
                        <Grid item xs={6}>
                           {colorOptions.length > 0 &&
                              <FormSelectLocalized field={"color"} locale={locale} label={"Color"}
                                                   options={colorOptions} placeholders={masterItem?.color}/>}
                           {colorOptions.length === 0 &&
                              <FormInputLocalized field={"color"} locale={locale} label={"Color"}
                                                  placeholders={masterItem?.color}
                              />
                           }
                        </Grid>
                        <Grid item xs={6}>
                           {productTypes.length > 0 &&
                              <FormSelectLocalized field={"type"} locale={locale} label={"Product type"}
                                                   options={productTypes} placeholders={masterItem?.type}/>}
                           {productTypes.length === 0 &&
                              <FormInputLocalized field={"type"} locale={locale} label={"Product type"}
                                                  placeholders={masterItem?.color}
                              />
                           }
                        </Grid>
                     </Grid>

                     <Grid container sx={{marginTop: '1px'}} spacing={1}>
                        <Grid container item xs={6}>
                           <Grid item xs={12}>
                              <FormInputLocalized field={"size"} locale={locale} label={"Size"}
                                                  placeholders={masterItem?.size}
                                                  InputProps={{
                                                     endAdornment: showSizeAlert && <Box sx={{
                                                        backgroundColor: '#f0f0f0',
                                                        color: 'red',
                                                        padding: '12px 10px 12px 14px',
                                                        marginRight: '-12px',
                                                        fontSize: '90%',
                                                        fontWeight: 'bold',
                                                        whiteSpace: 'nowrap',
                                                     }}>Mismatch?</Box>
                                                  }}
                              />

                           </Grid>
                           <Grid item xs={12}>
                              {vendorOptions.length > 0 &&
                                 <FormInputFree name={"vendor"} label={"Vendor"}
                                                options={vendorOptions} placeholder={masterItem?.vendor}/>}
                              {vendorOptions.length === 0 &&
                                 <FormInputText name={"vendor"} label={"Vendor"} placeholder={masterItem.vendor}/>
                              }
                           </Grid>
                        </Grid>
                        <Grid item xs={6}>
                           <Box sx={{
                              paddingLeft: '0px',
                              paddingRight: '0px',
                              paddingBottom: '5px'
                           }}>

                              <FormInputFree label={"Add tag"} name={"newTag"} options={tags}
                                             onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                   e.preventDefault();
                                                }
                                             }}
                                             onKeyUp={(e) => {
                                                if (e.key === 'Enter') {
                                                   let newTag = getValues('newTag');
                                                   if (!fields.find(field => field.tag === newTag)) {
                                                      append({
                                                         tag: newTag,
                                                         auto: false
                                                      });
                                                   }
                                                   setValue('newTag', '', {
                                                      shouldTouch: false
                                                   });
                                                }
                                             }}
                              />
                              {fields.map((field, i) => {
                                 if (field.auto) {
                                    return <Chip key={'tag_' + field?.tag} sx={{marginRight: '2px'}} size={"small"}
                                                 label={field?.tag}/>
                                 } else {
                                    return <Chip key={'tag_' + field?.tag} sx={{marginRight: '2px'}} size={"small"}
                                                 label={field?.tag}
                                                 variant={"outlined"} onDelete={() => {
                                       remove(i);
                                    }}/>
                                 }
                              })}
                           </Box>
                        </Grid>
                     </Grid>
                  </PageContent>
               </Grid>
               <Grid item {...breakpoints2Column}>
                  <PageContent>
                     <Grid container columnSpacing={2} rowSpacing={1}>
                        <Grid item xs={12} container justifyContent={"space-between"} alignItems={"baseline"}>
                           <TitleText type={"h2"}>Item {item?.itemNr || 'New'}</TitleText>
                           <BodyText
                              type={"subtitle2"}>Created {(item?.createdAt ? dayjs(item?.createdAt).format('DD MMM YYYY') : undefined) || 'just now'}</BodyText>
                        </Grid>
                        <Grid item xs={12}>
                           <FormInputSelect required={true} options={[{
                              id: 'NONE',
                              name: "None"
                           }, {
                              id: 'DRAFT',
                              name: "Draft"
                           }, {
                              id: 'REVIEW',
                              name: "Review"
                           }, {
                              id: 'READY',
                              name: "Ready"
                           }]} name={"itemState"} control={control} label={"Item state"}/>
                        </Grid>
                        <Grid item xs={6}>
                           <FormInputText disabled={isBrandHouse} name={"stockLocation"} label={"Location"}/>
                        </Grid>
                        <Grid item xs={6}>
                           <FormInputText disabled={isBrandHouse} name={"stockLocationBag"} label={"Location Bag"}/>
                        </Grid>
                        <Grid item xs={6}>
                           <FormInputReadonly label={"Warehouse EAN"} value={item?.itemEan || '-'}/>
                        </Grid>
                        <Grid item xs={6}>
                           <FormInputNumber name={"sortorder"} label={"Sortorder"}/>
                        </Grid>

                        {!isBrandHouse && <Grid item xs={12}>
                           <FormInputText name={"note"} label={"Warehouse note"}/>
                        </Grid>}

                        {!isBrandHouse && <Grid item xs={12}>
                           <FormInputText name={"nfcTag"} label={"NFC Tag"}/>
                        </Grid>}

                        <Grid item xs={12}>
                           <FormInputSelectMultiple name={"seasonIds"} label={"Seasons"}
                                                    options={seasons.map(season => {
                                                       return {
                                                          id: season.id!,
                                                          label: season.name!
                                                       }
                                                    })}/>
                        </Grid>

                        {!isBrandHouse &&
                           <Grid item xs={12}>
                              <Box sx={{display: 'flex'}}>
                                 <FormInputCheckbox color={"secondary"} name={"missing"} label={"Missing"}/>
                                 <FormInputCheckbox color={"secondary"} name={"checked"} label={"Checked"}/>
                                 <FormInputCheckbox color={"secondary"} name={"unavailable"} label={"Not for sale"}/>
                                 <FormInputCheckbox color={"secondary"} name={"test"} label={"Test item"}/>
                              </Box>
                           </Grid>
                        }
                     </Grid>
                  </PageContent>
               </Grid>
               {optionSets.length > 0 && <Grid item {...breakpoints2Column}>
                  <PageContent>
                     <TitleText type={"h1"}>Options</TitleText>
                     <OptionSets optionSets={optionSets}/>
                  </PageContent>
               </Grid>}
               {tradeInComments && <Grid item {...breakpoints2Column}>
                  <PageContent>
                     <Grid container sx={{marginTop: '1px'}} spacing={1}>
                        <Grid item xs={12}>
                           <TitleText type={"h1"}>Trade-in comments</TitleText>
                           {commentFields.map((f: any, i) => {
                              const language = Languages.get(f.locale)?.name
                              return <FormInputText name={`comment[${i}].value`} key={'comment_' + language}
                                                    label={language || f.locale}/>
                           })}
                           <FormInputCheckbox name={"commentApproved"} label={"Approve comment"}/>
                        </Grid>
                     </Grid>
                  </PageContent>
               </Grid>}
               <Grid item {...breakpoints2Column}>
                  <PageContent>
                     <TitleText type={"h1"}>Price</TitleText>
                     <Grid container spacing={2}>
                        {priceFields.map((field: any, i) => {
                           const currency = field.currency;
                           const masterBeforePrice = masterItem.beforePrices?.find(bef => bef?.currency === currency)?.value;
                           const sellingPrice = prices.find(price => price.currency === currency)?.value;
                           return (
                              <PriceCurrencyRow
                                 key={`${currency}-${sellingPrice}`}
                                 currency={currency}
                                 index={i}
                                 masterBeforePrice={masterBeforePrice}
                                 sellingPrice={sellingPrice}
                                 onRecalculate={() => {
                                    const values = getValues();
                                    recaculatePrices({
                                       variables: {
                                          brandHouseId,
                                          input: {
                                             condition: values.condition,
                                             typeLocalized: values.type.filter(typ => !!typ.value),
                                             beforePrices: (values.beforePrices||[]).filter(price => price.value),
                                             sourceType: values.sourceType,
                                          }
                                       }
                                    }).then(({data}) => {
                                       setPrices((data?.BrandItemCalculatePrices || []) as any);
                                    });
                                 }}
                              />
                           );
                        })}
                     </Grid>
                  </PageContent>
               </Grid>
               <Grid item {...breakpoints2Column}>
                  <PageContent>
                     <Grid container sx={{marginTop: '1px'}} spacing={1}>
                        <Grid item xs={6}>
                           <TitleText type={"h1"}>Item source</TitleText>
                           <Grid item xs={12}>
                              <FormInputSelect required={true} name={"sourceType"} label={"Source of item"}
                                               options={Object.values(ItemSourceType).map(key => ({
                                                  id: key,
                                                  name: key
                                               }))}/>
                           </Grid>
                        </Grid>
                        <Grid item xs={6}>
                           <TitleText type={"h1"}>Grading</TitleText>
                           <FormInputSelect required={true} options={[{
                              id: 1,
                              name: "Fair (1)"
                           }, {
                              id: 2,
                              name: "Good (2)"
                           }, {
                              id: 3,
                              name: "Excellent (3)"
                           }]} name={"condition"} control={control} label={"Condition"}/>
                        </Grid>
                     </Grid>
                  </PageContent>
               </Grid>
               <Grid item {...breakpoints2Column}>
                  <PageContent>
                     {sourceRender && sourceRender()}
                  </PageContent>
               </Grid>

               <Grid item {...breakpoints2Column}>
                  <PageContent>
                     <Grid container sx={{marginTop: '1px'}} spacing={1}>
                        <Grid item xs={12}>
                           <TitleText type={"h1"}>Master data</TitleText>
                           <FormInputText name={"originalEan"} label={"Original EAN"}
                                          placeholder={(masterItem?.originalEan || undefined)}/>
                           <FormInputText name={"originalSku"} label={"Original SKU"}
                                          placeholder={masterItem?.originalSku || undefined}/>
                        </Grid>
                     </Grid>
                  </PageContent>
               </Grid>

               <Grid item {...breakpoints2Column}>
                  <PageContent>
                     <TitleText type={"h2"}>Issues</TitleText>
                     <BrandItemProblems/>
                  </PageContent>
               </Grid>

               <Grid item {...breakpoints1Column}>
                  <PageContent>
                     <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                        <TitleText type={"h2"}>Images</TitleText>
                     </Grid>
                     <Grid item xs={12}>
                        <BrandItemImages brandHouseId={brandHouseId}
                                         brandProductSizeId={masterItem.brandProductSizeId}/>
                     </Grid>
                  </PageContent>
               </Grid>

            </Grid>

         </form>
      </FormProvider>
   )
}


type CopyTextProps = {
   text: string
}

const CopyText = ({
                     text
                  }: CopyTextProps) => {
   const editorState = useEditorState();

   const onCopyText = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (!editorState.$selection) {
         editorState.$el?.focus()
      }
      document.execCommand('insertHTML', false, text);
   }

   if (editorState.htmlMode) {
      return null;
   }

   return <button type={"button"} className={"rsw-btn"} onClick={onCopyText}><GetAppIcon sx={{fontSize: '14px'}}/>
   </button>
}


type FormInputLocalizedProps = StandardTextFieldProps & {
   field: any
   locale: string
   label: string
   html?: boolean
   placeholders?: LocalizedField[]
}

const FormInputLocalized = ({
                               field, locale, label, html, placeholders,
                               ...rest
                            }: FormInputLocalizedProps) => {
   const form = useFormContext<BrandEditorItem>();
   const {control} = form;

   const {fields} = useFieldArray<BrandEditorItem>({
      control,
      name: field!
   });

   return <>{fields.map((f: any, i) => {
      const place = placeholders?.find(res => res?.locale === locale)
      if (f.locale === locale) {
         if (html) {
            return <FormInputHtml key={'localized_' + field + '_' + i} placeholder={place?.value}
                                  name={`${field}[${i}].value`}
                                  label={label} height={"150px"}
                                  toolbarButton={place?.value ? <CopyText text={place?.value}/> : <></>}
            />
         } else {
            return <FormInputText key={'localized_' + field + '_' + i} placeholder={place?.value}
                                  name={`${field}[${i}].value`} label={label} {...rest}/>
         }
      } else {
         return null;
      }
   })}</>
}


type FormSelectLocalizedProps = FormControlProps & {
   field: any
   locale: string
   label: string
   options: string[]
   placeholders?: LocalizedField[]
}

const FormSelectLocalized = ({
                                field, locale, label, placeholders, options, ...rest
                             }: FormSelectLocalizedProps) => {
   const form = useFormContext<BrandEditorItem>();
   const {control} = form;

   const {fields} = useFieldArray<BrandEditorItem>({
      control,
      name: field!
   });

   return <>{fields.map((f: any, i) => {
      const place = placeholders?.find(res => res?.locale === locale)
      if (f.locale === locale) {
         return <FormInputFree
            key={'selectlocalized_' + locale + '_' + i}
            name={`${field}[${i}].value`} control={control}
            label={label}
            placeholder={place?.value}
            options={options}/>
      } else {
         return null;
      }
   })}</>
}


type TranslateField = {
   field: string
   fieldLanguage: string
   translations: SourceTranslation[]
}

type SourceTranslation = {
   sourceLanguage: string
   sourceText: string
}

// Helper function to find translation fields needing update
export const findFieldsToTranslate = (
   locales: string[],
   item: BrandEditorItem,
   masterItem: BrandEditorItem
): TranslateField[] => {
   const translationRequests: TranslateField[] = [];
   const fieldsToCheck = ['name', 'description', 'color', 'size', 'type'] as const;

   for (const field of fieldsToCheck) {
      // For each language that needs translation
      for (const targetLanguage of locales) {
         // Get current value for this field in target language
         const targetField = item[field]?.find(f => f.locale === targetLanguage);

         // Skip if target already has a value
         if (targetField?.value?.trim()) continue;

         // Skip if master item has a value
         const masterField = masterItem[field]?.find(f => f.locale === targetLanguage);
         if (masterField?.value?.trim()) continue;

         // Find all possible source languages (excluding target language)
         const possibleTranslations: SourceTranslation[] = [];

         for (const sourceLanguage of locales) {
            if (sourceLanguage === targetLanguage) continue;

            const sourceText = item[field]?.find(f => f.locale === sourceLanguage)?.value?.trim();
            const masterText = masterItem[field]?.find(f => f.locale === sourceLanguage)?.value?.trim();
            if (sourceText || masterText) {
               possibleTranslations.push({
                  sourceLanguage,
                  sourceText: (sourceText || masterText)!
               });
            }
         }

         // If we found any source texts, create a translation request
         if (possibleTranslations.length > 0) {
            translationRequests.push({
               field,
               fieldLanguage: targetLanguage,
               translations: possibleTranslations
            });
         }
      }
   }

   return translationRequests;
};