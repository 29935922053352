import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import {FlexList} from "../../components/flexlist/FlexList";
import {
   EnumIntegrationMessageMessage,
   FilterFindManyIntegrationMessageInput,
   IntegrationMessage,
   SortFindManyIntegrationMessageInput,
   useIntegrationConnectorsQuery,
   useIntegrationMessagesManyLazyQuery,
   useMessagesPageInstanceQuery
} from "../../Queries";
import {FlexFilter, FlexListColumn, FlexListRequestData} from "../../components/flexlist/FlexTypes";
import dayjs from "dayjs";
import {Box, Chip, Dialog, DialogContent, LinearProgress, Skeleton} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MessageDetailPanel} from "./MessageDetailPanel";

type SelectedRow = {
   refId: string,
   refType: string
   messageId: string
}

export const MessagesPage = () => {
   const [selected, setSelected] = useState<SelectedRow | undefined>(undefined);
   const [filters, setFilters] = useState<FlexFilter<FilterFindManyIntegrationMessageInput>[]>([])

   const [requestData] = useIntegrationMessagesManyLazyQuery({
      fetchPolicy: "no-cache"
   });

   const {data} = useIntegrationConnectorsQuery()

   const columns: FlexListColumn<IntegrationMessage>[] = [{
      id: "time",
      label: "Time",
      get: (row) => {
         return row.created
      },
      render: (data) => {
         const momentInTime = dayjs(data);
         const ageSeconds = Math.abs((new Date().getTime() - momentInTime.toDate().getTime()) / 1000);
         let color: string = "#F8F8F8";
         if (ageSeconds < 60) {
            color = "#00FF00";
         } else if (ageSeconds < 120) {
            color = "#40FF40";
         } else if (ageSeconds < 1200) {
            color = "#80FF80";
         } else if (ageSeconds < 3600) {
            color = "#C0FFC0";
         } else if (ageSeconds < 7200) {
            color = "#F0FFF0";
         }
         return <div style={{display: 'flex'}}>
            <div style={{
               marginRight: '16px',
               border: '1px solid #808080',
               width: '20px',
               backgroundColor: color,
               borderRadius: '50%'
            }}/>
            {momentInTime.format('HH:mm:ss, D. MMM')}
         </div>
      }
   }, {
      id: "connector",
      label: "Connection",
      field: "instanceId",
      render: (data, row) => {
         return <InstanceDetails instanceId={data}/>
      }
   }, {
      id: "name",
      label: "Name",
      get: (row) => {
         return row?.refShop?.name || row?.refBrandHouse?.name || row?.refLogisticsPartner?.name || 'No-name'
      }
   }, {
      id: "message",
      field: "message",
      label: "Message"
   }, {
      id: "Latency",
      label: "Latency",
      field: "_id",
      render: (data, row) => {
         const timing: string[] = []
         if (row?.created) {
            if (row?.sent) {
               const spent = new Date(row.sent).getTime() - new Date(row.created).getTime();
               timing.push(spent + "ms")
            } else {
               timing.push("Not sent")
            }
         } else {
            timing.push("Error")
         }
         return <Box>{timing.join(" / ")}</Box>
      }
   }, {
      id: "Time",
      label: "Response time",
      field: "_id",
      render: (data, row) => {
         const timing: string[] = []
         if (row?.sent) {
            if (row?.resultTs) {
               const spent = new Date(row.resultTs).getTime() - new Date(row.sent).getTime();
               timing.push(spent + "ms")
            } else if (row?.failed) {
               const spent = new Date(row.failed).getTime() - new Date(row.sent).getTime();
               timing.push(spent + "ms")
            }
         } else {
            timing.push("No timing")
         }
         return <Box>{timing.join(" / ")}</Box>
      }
   }, {
      id: "Status",
      label: "Status",
      field: "messageFailed",
      render: (data, row) => {
         if (data === undefined || data === null) {
            return <Chip color={"warning"} label={"Awaiting response"}/>
         } else if (data) {
            return <Chip color={"error"} label={"Failed"}/>
         } else {
            return <Chip color={"success"} label={"Success"}/>
         }
      }
   }];


   const onRequestData = async (search: string | undefined, sort: string | undefined, page: number, filter?: any) => {
      const {data} = await requestData({
         variables: {
            page: page,
            sort: (sort as SortFindManyIntegrationMessageInput) || SortFindManyIntegrationMessageInput._ID_DESC,
            perPage: 20,
            search: search,
            filter
         }
      });

      const resultData = data?.IntegrationMessages?.items || []
      return {
         data: resultData,
         totalCount: data?.IntegrationMessages?.count || 0
      } as FlexListRequestData<IntegrationMessage>
   }

   const onSelect = (row: IntegrationMessage) => {
      setSelected({
         refId: row.refId,
         refType: row.refType,
         messageId: row._id
      })
   }

   useEffect(() => {
      if (data) {
         const integrationFilter: FlexFilter<FilterFindManyIntegrationMessageInput> = {
            id: 'integration',
            label: 'Integration',
            options: []
         }
         data.IntegrationInstances.forEach(instance => {
            integrationFilter.options.push({
               id: instance._id,
               label: instance.connector?.name || 'Unknown connector',
               subtext: instance.refName || undefined,
               filter: {
                  instanceId: instance._id
               }
            })
         });
         integrationFilter.options.sort((a, b) => a.label.localeCompare(b.label))

         setFilters([{
            id: 'status',
            label: 'Status',
            options: [{
               label: 'Failed',
               filter: {
                  messageFailed: true
               }
            }, {
               label: 'Success',
               filter: {
                  messageFailed: false,
               }
            }]
         }, integrationFilter, {
            id: 'type',
            label: 'Type',
            options: Object.values(EnumIntegrationMessageMessage).map(msg => ({
               label: msg,
               filter: {
                  message: msg
               }
            }))
         }]);
      }
   }, [data]);

   if (!data || filters.length === 0) {
      return <LinearProgress/>
   }

   return <Page>
      <PageHeader title={"Messages sent to connectors"}>
      </PageHeader>
      {selected &&
         <Dialog open={!!selected} onClose={() => setSelected(undefined)} fullWidth maxWidth={"md"}>
            <DialogContent>
               <MessageDetailPanel {...selected}/>
            </DialogContent>
         </Dialog>
      }
      <PageContent>
         <FlexList globalId={"messagesList"} onSelectRow={onSelect} columns={columns} itemsPerPage={40}
                   filters={filters}
                   onRequestData={onRequestData}/>
      </PageContent>
   </Page>

}

type InstanceDetailsProps = {
   instanceId: string
}

const InstanceDetails = ({instanceId}: InstanceDetailsProps) => {
   const {data} = useMessagesPageInstanceQuery({
      variables: {
         instanceId
      }
   });
   if (!data) {
      return <Skeleton variant={"rectangular"}/>
   }

   return <Box>{data?.IntegrationInstance?.name}</Box>
}
