import * as React from "react";
import {FC, useCallback} from "react";
import {Alert} from "@mui/material";
import {DateFormat} from "../../../utility/DateFormat";
import Grid from "@mui/material/Grid";
import {
   EnumOrder2FulState,
   EnumOrder2OrderState,
   EnumOrder2PayState,
   EnumOrder2TrackingOrderState,
   EnumOrder2TrackingReturnState,
   FilterFindManyOrder2Input,
   Order2Item,
   OrderFragment,
   SortFindManyOrder2Input,
   useOrdersListLazyQuery
} from "../../../Queries";
import {FlexFilter, FlexListColumn, FlexSort, FlexTab} from "../../../components/flexlist/FlexTypes";
import {FlexList} from "../../../components/flexlist/FlexList";

type OrdersPanelProps = {
   listId: string
   brandHouseId?: string
   shopId?: string
   consumerId?: string
   columns?: string[]
   maxRows?: number
   hideHeader?: boolean,
   onSelectOrder?: (order: OrderFragment) => void
}

const PAGE_SIZE = 30;

export const OrdersList: FC<OrdersPanelProps> = ({
                                                    listId,
                                                    shopId,
                                                    brandHouseId,
                                                    consumerId,
                                                    columns,
                                                    maxRows,
                                                    hideHeader,
                                                    onSelectOrder
                                                 }) => {


   const [fetchMore] = useOrdersListLazyQuery();

   const sortSpec: FlexSort<SortFindManyOrder2Input>[] = [{
      id: 'CreatedAsc',
      label: 'Created (oldest first)',
      value: SortFindManyOrder2Input._ID_ASC
   }, {
      id: 'CreatedDesc',
      label: 'Created (newest first)',
      value: SortFindManyOrder2Input._ID_DESC
   }]

   const filterSpec: FlexFilter<FilterFindManyOrder2Input>[] = [{
      id: 'fulState',
      label: 'Fulfillment',
      options: [{
         label: 'Fulfilled',
         filter: {
            fulState: EnumOrder2FulState.FULFILLED
         }
      }, {
         label: 'Unfulfilled',
         filter: {
            fulState: EnumOrder2FulState.UNFULFILLED,
         }
      }, {
         label: 'Other',
         filter: {
            OR: [{
               fulState: EnumOrder2FulState.OPEN
            }, {
               fulState: EnumOrder2FulState.ON_HOLD
            }, {
               fulState: EnumOrder2FulState.IN_PROGRESS
            }, {
               fulState: EnumOrder2FulState.PARTIALLY_FULFILLED
            }, {
               fulState: EnumOrder2FulState.PENDING_FULFILLMENT
            }, {
               fulState: EnumOrder2FulState.SCHEDULED
            }, {
               fulState: null
            }]
         }
      }]
   }, {
      id: 'payState',
      label: 'Capture',
      options: [{
         label: 'Paid',
         filter: {
            payState: EnumOrder2PayState.PAID
         }
      }, {
         label: 'Unpaid',
         filter: {
            payState: EnumOrder2PayState.UNPAID
         }
      }, {
         label: 'Pending',
         filter: {
            payState: EnumOrder2PayState.PENDING
         }
      }, {
         label: 'Refunded',
         filter: {
            payState: EnumOrder2PayState.REFUNDED
         }
      }, {
         label: 'Other',
         filter: {
            payState: EnumOrder2PayState.OTHER
         }
      }]
   }, {
      id: 'orderState',
      label: 'Order state',
      options: [{
         label: 'Open',
         filter: {
            orderState: EnumOrder2OrderState.OPEN
         }
      }, {
         label: 'Completed',
         filter: {
            orderState: EnumOrder2OrderState.COMPLETED,
         }
      }, {
         label: 'Cancelled',
         filter: {
            orderState: EnumOrder2OrderState.CANCELLED,

         }
      }, {
         label: 'Partial',
         filter: {
            orderState: EnumOrder2OrderState.PARTIAL,
         }
      }]
   }, {
      id: 'delivery',
      label: 'Shipping status',
      options: [{
         label: 'Not sent',
         filter: {
            trackingOrderState: EnumOrder2TrackingOrderState.NOTSENT
         }
      }, {
         label: 'Transit',
         filter: {
            trackingOrderState: EnumOrder2TrackingOrderState.TRANSIT
         }
      }, {
         label: 'Received',
         filter: {
            trackingOrderState: EnumOrder2TrackingOrderState.RECEIVED,
         }
      }, {
         label: 'Awaiting pickup',
         filter: {
            trackingOrderState: EnumOrder2TrackingOrderState.PICKUP_AVAILABLE,
         }
      }, {
         label: 'Error',
         filter: {
            trackingOrderState: EnumOrder2TrackingOrderState.ERROR,
         }
      }]
   }, {
      id: 'return',
      label: 'Return status',
      options: [{
         label: 'Not sent',
         filter: {
            trackingReturnState: EnumOrder2TrackingReturnState.NOTSENT
         }
      }, {
         label: 'Transit',
         filter: {
            trackingReturnState: EnumOrder2TrackingReturnState.TRANSIT
         }
      }, {
         label: 'Received',
         filter: {
            trackingReturnState: EnumOrder2TrackingReturnState.RECEIVED,
         }
      }, {
         label: 'Awaiting pickup',
         filter: {
            trackingReturnState: EnumOrder2TrackingReturnState.PICKUP_AVAILABLE,
         }
      }, {
         label: 'Error',
         filter: {
            trackingReturnState: EnumOrder2TrackingReturnState.ERROR,
         }
      }]
   }]

   const tabsSpec: FlexTab[] = [{
      id: 'all',
      label: 'All',
      filters: [],
      columns: columns
   }, {
      id: 'open',
      label: 'Open',
      filters: [{
         filterId: 'fulState',
         values: ['Unfulfilled', 'Other']
      }, {
         filterId: 'orderState',
         values: ['Open']
      }, {
         filterId: 'payState',
         values: ['Unpaid', 'Pending']
      }],
      showCount: true,
      columns: columns
   }, {
      id: 'unpaid',
      label: 'Unpaid',
      filters: [{
         filterId: 'payState',
         values: ['Unpaid']
      }, {
         filterId: 'orderState',
         values: ['Open', 'Completed', 'Partial']
      }],
      showCount: true,
      columns: columns
   }, {
      id: 'returnissues',
      label: 'Refund issues',
      filters: [{
         filterId: 'fulState',
         values: ['Fulfilled']
      }, {
         filterId: 'payState',
         values: ['Paid']
      }, {
         filterId: 'orderState',
         values: ['Completed']
      }, {
         filterId: 'return',
         values: ['Received']
      }],
      showCount: true,
      columns: columns
   }, {
      id: 'shipmentissues',
      label: 'Shipment issues',
      filters: [{
         filterId: 'delivery',
         values: ['Awaiting pickup']
      }],
      showCount: true,
      columns: columns
   }]

   let brandColumn: FlexListColumn<OrderFragment> = {
      id: 'brand',
      label: 'Brand',
      field: 'shopId',
      width: 5,
      align: 'left',
      get: (row) => {
         return row.shop?.name
      },
      ellipsis: true
   };
   const allColumns: FlexListColumn<OrderFragment>[] = [{
      id: 'orderDate',
      label: 'Date',
      get: (row: OrderFragment) => {
         if (row.orderDate) {
            return new Date(row.orderDate).getTime()
         } else {
            return 0;
         }
      },
      render: (date, row) => {
         if (date) {
            return DateFormat.toPresent(new Date(date));
         } else {
            return "-"
         }
      },
      ellipsis: true,
      width: 10
   }, {
      id: 'consumer',
      label: 'Customer',
      get: (order) => {
         return order.consumer.mail
      },
      render: (name, order) => {
         return <div>
            <div>{order?.consumer.name}</div>
            <div>{order?.consumer.mail}</div>
         </div>
      },
      ellipsis: true,
      width: 15
   }, {
      id: 'orderUrl',
      label: 'Order',
      field: 'orderUrl',
      render: (url, row) => {
         if (url) {
            return <a onClick={(e) => {
               e.stopPropagation()
            }} href={url} target={"_blank"} rel={"noreferrer"}>{row?.orderName}</a>
         } else {
            return null;
         }
      },
      ellipsis: true,
      width: 8
   }, {
      id: 'fulfillment',
      label: 'Fulfillment',
      field: 'fulState',
      width: 8,
      ellipsis: true
   }, {
      id: 'payment',
      label: 'Payment',
      field: 'payState',
      width: 8,
      ellipsis: true
   }, {
      id: 'orderState',
      label: 'Order package',
      field: 'trackingOrderState',
      width: 8,
      ellipsis: true
   }, {
      id: 'returnState',
      label: 'Return package',
      field: 'trackingReturnState',
      width: 8,
      ellipsis: true
   }, {
      id: 'items',
      label: "Items",
      get: (row) => {
         return row.lines
      },
      render: (lines: Order2Item[], row) => {
         const returned = lines.some(line => line.returned);
         const cancelled = lines.some(line => line.cancelled);
         return <Grid container>
            <Grid item xs={3}>
               {lines.length} items
            </Grid>
            <Grid item xs={9}>
               {returned &&
                  <Alert icon={false} variant={"filled"} style={{paddingTop: '0', paddingBottom: '0'}}
                         color={"warning"}>Some items returned</Alert>}
               {cancelled &&
                  <Alert icon={false} variant={"filled"} style={{paddingTop: '0', paddingBottom: '0'}}
                         color={"error"}>Some items cancelled</Alert>}
            </Grid>
         </Grid>
      },
      width: 20
   }];

   let listColumns: FlexListColumn<OrderFragment>[] = [];
   if (!shopId && !brandHouseId) {
      listColumns = [brandColumn, ...allColumns]
   } else {
      listColumns = allColumns
   }

   const onRequestData = useCallback(async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const effectiveFilter: FilterFindManyOrder2Input = {
         ...filter,
         _operators: {
            silenced: {ne: true}
         }
      }
      if (shopId) {
         effectiveFilter._operators = {
            ...effectiveFilter._operators,
            shopId: {
               in: [shopId]
            }
         }
      }

      if (brandHouseId) {
         effectiveFilter._operators = {
            ...effectiveFilter._operators,
            brandHouseId: {
               in: [brandHouseId]
            }
         }
      }

      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: sort as SortFindManyOrder2Input || SortFindManyOrder2Input._ID_DESC,
            perPage: maxRows || PAGE_SIZE,
            search: search,
            filter: effectiveFilter
         }
      });
      return {
         data: data?.OrderPagination?.items || [],
         totalCount: data?.OrderPagination?.count || 0
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fetchMore]);

   return <FlexList globalId={listId}
                    columns={listColumns}
                    filters={filterSpec}
                    itemsPerPage={PAGE_SIZE}
                    sorts={sortSpec}
                    tabs={tabsSpec}
                    options={{
                       maxRows: maxRows,
                       hideHeader: hideHeader || false
                    }}
                    onSelectRow={onSelectOrder}
                    onRequestData={onRequestData}
   />
}
